import React from 'react';
import { Link } from 'react-router-dom';
import { useRouteInterpolation } from '../../util/routeUtil';
import ElementIcon from '../ElementIcon';


function WorkTrayIcon({ element, scope }) {
    const { label } = element;

    const route = useRouteInterpolation(element.route, scope);
    const IconLabel = () => label
        ? <div className='col-lg-6 align-self-sm-center label'><NewlineText text={label} /></div>
        : null;

    return (
        <div className='workTrayIcon'>
            {route ? (
                <Link className='row' to={route} target={element.targetBlank ? "_blank" : "_self"}>
                    <button className="btn btn-secondary">
                        <ElementIcon element={element} />
                    </button>
                    <IconLabel />
                </Link>
            ) : (
                <div className='row'>
                    <ElementIcon element={element} />
                    <IconLabel />
                </div>
            )}
        </div>
    );
}

WorkTrayIcon.rootSchema = {
    "properties": {
        "image": {
            ":classNames": "from-col-1-size-1",
            "title": "Button Image",
            "ui:field": "SelectImageField",
            "type": "object",
            "iconSizing": true,
            "map:field": "",
            "properties": {}
        },
        "fontSize": { 
            "title": "Icon Size",
            "type": "string",
            "enum": ["1em", "2em", "4em", "6em", "8em", "10em"],
            "map:field": {"default": "icons.size", "path": "fontSize"}
        },
        "label": { 
            ":classNames": "from-col-1-size-1",
            "title": "Label",
            "type": "string",
            "map:field": "label"
        },
        "route": { 
            ":classNames": "from-col-2-size-1",
            "title": "Route",
            "type": "string",
            "map:field": "route"
        },
        "targetBlank": { 
            ":classNames": "from-col-2-size-1",
            "title": "External Route",
            "type": "boolean",
            "map:field": "targetBlank"
        },
    }
}


function NewlineText(props) {
    return props.text.split('\n').map((str, idx) => <p key={idx}>{str}</p>);
}

export default WorkTrayIcon;