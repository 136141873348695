import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from "reactstrap";

function CollapsableTreeSelect(props) {
    const [current, setCurrent] = useState();
    const [focused, setFocused] = useState(false)
    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)
    const {
        emptyText,
        rootNodes,
        valueNode,
        onSelect,
        collapsed, setCollapsed,
        isOpen, toggle,
        disabled
    } = useCollapsableTreeSelectHooks(props, current, setCurrent, focused);

    useEffect(() => setCurrent((valueNode?.labelNoDepth)), [valueNode]);
    return (<Dropdown className="collapsable-tree-select" disabled={disabled} isOpen={isOpen} toggle={toggle}>
        
        <DropdownToggle>
            <Input
                type="text"
                value={current}
                onChange={(e) => (
                    setCurrent(e.target.value)
                )}
                onFocus={onFocus}
                onBlur={onBlur}
            >
            {}
            </Input>
            {/* <NodeLabel node={valueNode} emptyText={emptyText} /> */}
        </DropdownToggle>
        {isOpen ? (<DropdownMenu>{!(rootNodes?.length) ? (
            "empty tree"
        ) : (rootNodes?.map((node) => (
            <TreeNode key={node.id}
                node={node}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                onSelect={onSelect}
                current={current}
            />
        )))}</DropdownMenu>) : null}
    </Dropdown>);
}

function useCollapsableTreeSelectHooks({
    name,
    value,
    emptyText,
    options, parentId, id, label,
    rootNodes: propRootNodes,
    disabled,
    onChange,
    defaultCollapseLevel,
    current, setCurrent,
}) {
    const [isOpen, setOpen] = useState();
    const toggle = useCallback(() => setOpen(isOpen => !isOpen), [setOpen]);

    const rootNodes = useMemo(() => {
        if (propRootNodes) return propRootNodes;
        if (!options || !parentId) return null;
        const tsOptions = options.map(item => makeTreeNode(item, id, label, parentId));
        const rootNodes = organizeTreeNodes(tsOptions, current);
        visitTrees(rootNodes);
        return rootNodes;
    }, [propRootNodes, options, parentId, id, label]);
    const nodesById = useMemo(() => {
        const nodesById = {};
        forEachTreeNodeIn(rootNodes, current => {
            nodesById[current.id] = current;
        });
        return nodesById;
    }, [rootNodes, value]);

    const valueNode = nodesById[value];
    const onSelect = useCallback((value) => {
        console.log('selected: ', value);
        onChange({ target: { name, value } });
    }, [name, onChange]);

    const [collapsed, _setCollapsed] = useState();
    const setCollapsed = useCallback((path, value) => _setCollapsed(collapsed => ({
        ...(collapsed || {}),
        [path]: value
    })), [_setCollapsed]);
    useEffect(() => {
        if (rootNodes) {
            const collapsed = {};
            if (defaultCollapseLevel) {
                forEachTreeNodeIn(rootNodes, current => {
                    collapsed[current.path] = current.depth >= defaultCollapseLevel;
                });
            }
            _setCollapsed(collapsed);
        }
    }, [rootNodes, defaultCollapseLevel]);

    return {
        emptyText,
        rootNodes,
        valueNode,
        disabled,
        onSelect,
        collapsed, setCollapsed,
        isOpen: !!isOpen, toggle
    };
}


function makeListMap(list, key) {
    return list.reduce((_, item) => {
        _[item[key]] = item;
        return _;
    }, {});
}

export function makeTreeNode(item, idAttr, labelAttr, parentIdAttr) {
    return ({
        id: item[idAttr] ? item[idAttr] : item,
        label: item[labelAttr] ? item[labelAttr] : item,
        parentId: item[parentIdAttr],
        depth: 0,
        item
    });
}

export function organizeTreeNodes(nodesList, current) {
    const nodeMap = makeListMap(nodesList, 'id');
    const rootNodes = [];
    nodesList.sort((a, b) => a.label.localeCompare(b.label));
    nodesList.forEach(node => {
        const parent = node.parentId ? nodeMap[node.parentId] : null;
        if (parent) {
            node.parent = parent;
            if (!parent.children) parent.children = [];
            parent.children.push(node);
        } else {
            rootNodes.push(node);
        }
    });
    return rootNodes;
}

export function visitTrees(rootNodes) {
    const stack = rootNodes.map(node => ['', 0, '', node]);
    let visitIdx = 0;
    while (stack.length) {
        const [parentPath, depth, depthPrefix, current] = stack.shift();

        visitIdx += 1;
        if (!current.visitIdx) {
            const pathPrefix = parentPath ? `${parentPath}/` : '';
            const path = `${pathPrefix}${current.id}`;
            current.depth = depth;
            current.depthPrefix = depthPrefix;
            current.labelNoDepth = current.label;
            current.label = `${depthPrefix} ${current.label}`;
            current.path = path;
            current.visitIdx = visitIdx;

            if (current.children) {
                const depthPrefix2 = `${depthPrefix.replace(/\u251D/g, '\u2502').replace(/\u2515/g, '\u00A0')}`;
                current.children[0].isFirst = true;
                current.children[current.children.length - 1].isLast = true;
                const childDepthPrefix = `${depthPrefix2}\u251D`;
                const lastChildDepthPrefix = `${depthPrefix2}\u2515`;
                stack.unshift(...current.children.map(child => {
                    return [
                        path,
                        depth + 1,
                        child.isLast ? lastChildDepthPrefix : childDepthPrefix,
                        child
                    ];
                }));
            }
        }
    }
}


function forEachTreeNodeIn(rootNodes, fn) {
    const stack = [...(rootNodes || [])];
    while (stack.length) {
        const current = stack.shift();
        fn(current);
        if (current.children) {
            stack.unshift(...current.children);
        }
    }
}

function TreeNode({
    node, collapsed, setCollapsed, onSelect, current
}) {
    const {
        id,
        path,
        children
    } = node;
    const ref = useRef();
    const hasChildren = !!(node?.children?.length);
    const isCollapsed = collapsed && collapsed[path];

    const onExpand = useCallback((e) => {
        e.stopPropagation();
        setCollapsed(path, !isCollapsed);
    }, [setCollapsed, path, isCollapsed]);

    const onKeyDown = useCallback((e) => {
        switch (e.keyCode) {
            case 39: // right
                console.log("isCollapsed", isCollapsed);
                if (isCollapsed) setCollapsed(path, false);
                break;
            case 37: // left
                if (!isCollapsed && hasChildren) setCollapsed(path, true);
                else {
                    const parentPath = path.split('/').slice(0, -1).join('/');
                    if (parentPath) {
                        const ddmenuEl = ref.current.parentElement.parentElement;
                        const parentEl = ddmenuEl.querySelector(`[path="${parentPath}"]`);
                        if (parentEl) parentEl.focus();
                        setCollapsed(parentPath, true);
                    }
                }
                break;
        }
    }, [isCollapsed, setCollapsed, ref])
    function getDescendantsHelper(child, childList){
        childList.push(child)
        if(child?.children){
            child.children.forEach(grandchild => {
                getDescendantsHelper(grandchild, childList)
            });
        }
    }
    const getDescendants = () => {
        const childList = []
        getDescendantsHelper(node ,childList)
        return [...childList];
    }
    const childrenInSearch = !current || getDescendants().some((descedant) => {
        return descedant.label?.toLowerCase().includes(current?.toLowerCase())
    })
    return (childrenInSearch && <>
        <DropdownItem tag="div" onClick={() => onSelect(id)}
            path={path}
            onKeyDown={onKeyDown}
        >
            <span ref={ref} className="depth-prefix">{node.depthPrefix}</span>
            {hasChildren && !current ? <Button onClick={onExpand} color="clear">
                <i className={isCollapsed ? 'fa fa-plus-square' : 'fa fa-minus-square'} />
            </Button> : null}
            <NodeLabel node={node} />
        </DropdownItem>
        {hasChildren && isCollapsed && !current ? null : (children?.map(child => {
            return (child.label?.toLowerCase().includes(current?.toLowerCase()) || childrenInSearch) && <TreeNode key={child.id}
                node={child}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                onSelect={onSelect}
                current={current}
            />
        }))}
    </>);
}

function NodeLabel({ node, emptyText }) {
    return node ? (
        <span className="label" title={node.labelNoDepth} >{node.labelNoDepth}</span>
    ) : (emptyText ? (
        <span className="label" title={emptyText} >{emptyText}</span>
    ) : null);
}


export default CollapsableTreeSelect;