import React, { useContext,useMemo, useState, useEffect } from "react";
import { Button, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { useHistory } from "react-router";
import { AuthContext } from "../context/AuthContext";
import UWEEntityAssignments from "./UWEEntityAssignments";
import UWEEntityHistory from "./UWEEntityHistory";
import UWEEntityNote from "./UWEEntityNote";
import UWEEntityProgress from "./UWEEntityProgress";
import UWEEntityApi from '../api/UWEEntityApi';
import Loader from '../components/Loader';

const DEFAULT_OPEN = true;

const SIDEBAR_COMPONENTS = {
    assignments: UWEEntityAssignments,
    notes: UWEEntityNote,
    text: NewlineText,
    history: UWEEntityHistory,
    progress: UWEEntityProgress
}

function SidebarComponents(props) {
    const history = useHistory();
    const { children, title, currentEntity, readOnly } = props;
    const propsComponents = props.onComponents || [];

    const auth = useContext(AuthContext);
    const permissions = ((auth || {}).user || {}).permissions || {};
    const manageAnything = permissions.ManageUsersInUnit || permissions.ManageOrganizationalUnits;

    const [_mainContent, setMainContent] = useState();
    const mainContent = _mainContent || "form";

    const [components, setComponents] = useState({});
    const isOpen = (obj) => {
        if(obj.mainContent === "instruction") return true;
        if(history.location.pathname.endsWith('new')) return false;
        return DEFAULT_OPEN;
    }
    useEffect(() => {
        setComponents(propsComponents.reduce((acc, obj) => ({
            ...acc,
            [obj.mainContent]: {
                open: isOpen(obj),
                position: obj.position
            }
        }), {}));
    }, []);

    const setComponent = (mainContent, action, position) => {
        setComponents(prevState => ({
            ...prevState,
            [mainContent]: {
                open: action,
                position
            }
        }));
    }
    
    const [showLeft, showRight] = useMemo(() => [
        Boolean(Object.values(components).filter(x => x.position === "left" && x.open).length),
        Boolean(Object.values(components).filter(x => x.position === "right" && x.open).length)
    ], [components])

    async function handleReactivar () {
        await UWEEntityApi.reactivateUWEEntity({ id: currentEntity.id });
        window.location.reload();
    }
    
    async function handleArchivar ()  {
        await UWEEntityApi.archiveUWEEntity({ id: currentEntity.id });
        window.location.reload();
    }

    const [hasLicense, setHasLicense] = useState(false);
    const [licenseFile, setLicenseFile] = useState('')
    const [licenseUrl, setLicenseUrl] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [modalLicense, setModalLicense] = useState(false);
    function modalLicenseToggle(transition = false) {
        setModalLicense(transition)
    };

    const pollForLicense = async (attempts = 1) => {
        return new Promise((resolve, reject) => {
            const attemptFetch = async (remainingAttempts) => {
                if (remainingAttempts <= 0) {
                    reject(new Error("License file not found after several attempts."));
                    return;
                }
                try {
                    const response = await UWEEntityApi.fetchLicense(currentEntity.id);
                    if (response) {
                        setHasLicense(true);
                        setLicenseFile(response);
                        setLicenseUrl(response.url);
                        resolve();
                    } else {
                        setTimeout(() => attemptFetch(remainingAttempts - 1), 5000);
                    }
                } catch (error) {
                    setTimeout(() => attemptFetch(remainingAttempts - 1), 5000);
                }
            };

            attemptFetch(attempts);
        });
    };

    useEffect(() => {
        if (title === "Conductor") {
            pollForLicense();
        }
    }, [currentEntity.id, title]);

    const generateLicense = async () => {
        setIsGenerating(true);
        try {
            await UWEEntityApi.generateLicenseImage(currentEntity.id);
            await pollForLicense(10); // Refresh license check
        } catch (error) {
            console.error('Error generating license:', error);
        } finally {
            setIsGenerating(false);
        }
    };

    async function handleLicenseAction() {
        if (hasLicense && licenseUrl) {
            modalLicenseToggle(true);
        } else {
            await generateLicense();
        }
    }
    const extension = ((licenseFile && licenseFile.name) || '').split('.').pop();
    const licenseButton = (
        <>
            <Button
            className="btn btn-primary float-right"
            style={{ marginRight: "15px" }}
            onClick={handleLicenseAction}
            disabled={isGenerating}
            >
                {hasLicense ? "Licencia" : "Generar Licencia"}
                {isGenerating && <Loader sm/>}
            </Button>
            <Modal isOpen={modalLicense}
                toggle={() => modalLicenseToggle()}
                className="modal-primary"
                size='xl'
                backdrop
            >
                <ModalHeader toggle={() => modalLicenseToggle()} >
                    Vista previa de Archivo
                </ModalHeader>
                <ModalBody style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    {
                        ["png","jpeg", "PNG", "JPEG", "jpg", "JPG"].includes(extension) ?
                        <img
                        src={licenseUrl}
                        alt="File"
                        width="75%"
                        style={{alignSelf: 'center'}}
                        /> :
                        <iframe
                        title="Files"
                        src={`https://docs.google.com/gview?url=${licenseUrl}&embedded=true`}
                        height="500"
                        width="100%"
                        frameBorder="0"
                        />
                    }
                </ModalBody>
                <ModalFooter>
                    <a className="btn btn-primary" href={licenseUrl} target="_blank" rel="noreferrer" >Descargar</a>
                    <Button onClick={() => modalLicenseToggle()} color="secondary">Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    );

    return children ? (
        <div className="form-container">
            <div className={`sidepanel sidepanel-left ${showLeft ? 'expanded' : ''}`}>
                {propsComponents.filter(x => x.position === "left").map((obj, idx) => {
                    const Component = SIDEBAR_COMPONENTS[obj.type];
                    return (mainContent !== obj.mainContent && currentEntity ?
                        <ExpandableTabSidePanel
                            key={idx}
                            title={obj.title}
                            expanded={components[obj.mainContent]?.open | 0}
                            setExpanded={() => setComponent(obj.mainContent, !components[obj.mainContent]?.open, obj.position)}
                            onMaximize={() => setMainContent(obj.mainContent)}
                        >
                            <Component auth={auth} text={obj.content} entity={currentEntity} totalExpanded={mainContent === obj.mainContent} readOnly={readOnly} />
                        </ExpandableTabSidePanel> : null)
                })
                }
            </div>
            <div className={`sidepanel sidepanel-right ${showRight ? 'expanded' : ''}`}>
                {propsComponents.filter(x => x.position === "right").map((obj, idx) => {
                    const Component = SIDEBAR_COMPONENTS[obj.type];
                    return (mainContent !== obj.mainContent && currentEntity ? <ExpandableTabSidePanel
                        key={idx}
                        title={obj.title}
                        expanded={components[obj.mainContent]?.open | 0}
                        setExpanded={() => setComponent(obj.mainContent, !components[obj.mainContent]?.open, obj.position)}
                        onMaximize={() => setMainContent(obj.mainContent)}
                    >
                        <Component auth={auth} text={obj.content} entity={currentEntity} totalExpanded={mainContent === obj.mainContent} readOnly={readOnly} />
                    </ExpandableTabSidePanel> : null)
                })}
            </div>
            <div className="form-component uweentity-form">
                <div className="uweentity-title">
                    <span>{currentEntity?.id ? `#${currentEntity?.data?.FFCaseNumber ? currentEntity.data.FFCaseNumber : currentEntity.id}: `: ''}{title}</span>
                    {currentEntity?.data?.umbrellaAgency && <span className="float-right umbrellaAgency">Agencia Principal: {currentEntity?.data?.umbrellaAgency}</span>}
                    {manageAnything && (
                        <button
                            className="btn btn-primary float-right"
                            style={{ marginRight: "15px" }}
                            onClick={() => currentEntity.isArchived ? handleReactivar() : handleArchivar()}
                        >
                            {currentEntity.isArchived ? "Reactivar" : "Archivar"}
                        </button>
                    )}
                    {title === "Conductor" && licenseButton}
                </div>
                {mainContent === "form" ? children : null}
                {propsComponents.map((obj, idx) => {
                    const Component = SIDEBAR_COMPONENTS[obj.type];
                    return (mainContent === obj.mainContent && currentEntity ?
                        <FullExpandedPanel
                            key={idx}
                            title={obj.title}
                            onClose={() => { setComponent(obj.mainContent, false, obj.position); setMainContent(); }}
                            onMinimize={() => setMainContent()}
                        >
                            <Component auth={auth} text={obj.content} entity={currentEntity} totalExpanded={mainContent === obj.mainContent} readOnly={readOnly} />
                        </FullExpandedPanel> : null);
                })}
            </div>
        </div>
    ) : null
}

function ExpandableTabSidePanel({ title, children, expanded, setExpanded, onMaximize }) {
    return expanded ? (<Card className="expandable-tab-side-panel-panel">
        <CardHeader>
            {title}
            <div className="btn-bar">
                {onMaximize ? (<Button onClick={onMaximize} title={`Expandir ${title}`} ><i className="fa fa-expand" /></Button>) : null}
                <Button onClick={() => { setExpanded(0); }} title={`Cerrar ${title}`} ><i className="fa fa-times" /></Button>
            </div>
        </CardHeader>
        <CardBody>{children}</CardBody>
    </Card>) : (
        <button
            className="expandable-tab-side-panel-tab"
            onClick={() => { setExpanded(1); }}
            title={`Cerrar ${title}`}
        >{title}</button>
    );
}

function FullExpandedPanel({
    title,
    onClose,
    onMinimize,
    children
}) {
    return (<Card className="uweentity-form-full-expanded-sidepanel">
        <CardHeader className="card-header">
            {title}
            <div className='btn-bar'>
                {onMinimize ? <Button onClick={onMinimize} title={`Minimizar ${title}`} ><i className="fa fa-compress" /></Button> : null}
                {onClose ? <Button onClick={onClose} title={`Cerrar ${title}`} ><i className="fa fa-times" /></Button> : null}
            </div>
        </CardHeader>
        <CardBody>{children}</CardBody>
    </Card>)
}

function NewlineText({ text }) {
    return text.split('\n').map((str, idx) => <p key={idx}>{str}</p>);
}

export default SidebarComponents;