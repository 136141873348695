import dayjs from "dayjs";
import _ from "lodash";
import clone from "./clone";
import { getObject, invertMap, mapObject, setObject } from "./mapObject";

function withSignature(signature, fn) {
    fn.signature = signature;
    return fn;
}

const DOMINICAN_TZ = 'America/Dominica'; // AST
const SLA_COLORS = ["success", "warning", "danger", ""];

const CHARSETS = {
    'w': 'abcdefghijklmnopqrstuvwxyz',
    'W': 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    '0': '0123456789',
    '!': '!@#$%^&*(),<.>;:[{]}\\/?|',
};

const jsonataFunctions = {
    deepMerge(...objs) { return _.merge({}, ...objs); },
    coalesce(...values) {
        for (let i = 0; i < values.length; i += 1) {
            const x = values[i];
            if (x !== null && x !== undefined) return x;
        }
    },
    charSet: withSignature(
        '<s:a<s>>',
        (cstype) => cstype.split('').reduce((_, cstype) => {
            const CS = CHARSETS[cstype];
            if (CS && !_.sets[cstype]) _.list.push(...CS);
            return _;
        }, { list: [], sets: {} }).list
    ),
    fromCharCode: withSignature(
        '<n:s>',
        String.fromCharCode
    ),
    fromCodePoint: withSignature(
        '<n:s>',
        String.fromCodePoint
    ),
    charAt: withSignature(
        '<sn:s>',
        (str, index) => str.charAt(index)
    ),
    charCodeAt: withSignature(
        '<sn:n>',
        (str, index) => str.charCodeAt(index)
    ),
    codePointAt: withSignature(
        '<sn:n>',
        (str, index) => str.codePointAt(index)
    ),
    range: withSignature(
        '<nn?n?:a<n>>',
        (from, to = undefined, step = 1) => {
            if (to === undefined) {
                to = from;
                from = 0;
            }

            const arr = [];
            for (let i = from; i < to; i += step) arr.push(i);

            return arr;
        }
    ),
    copy: data => {
        navigator.clipboard.writeText(`${data}`);
    },
    log: (...args) => { console.log(...args); },
    alert: text => { alert(text); return text; },
    isJson: text => {
        try {
            JSON.parse(text);
            return true;
        } catch (e) {
            return false;
        }
    },

    mapObject,
    invertMap,
    getObject,
    setObject,

    pathUp(path, i = 0) {
        const components = path.split('.');
        return components.slice(0, Math.max(0, components.length - i - 1)).join('.');
    },

    pathJoin(...paths) {
        return paths.reduce((_, p) => {
            if (!p) return _;
            if (p.startsWith('.')) p = p.substr(1);
            if (!p) return _;
            if (p.endsWith('.')) p = p.substr(0, p.length - 1);
            p.split('.').forEach(component => {
                if (component === '') {
                    _.pop();
                } else {
                    _.push(component);
                }
            });
            return _;
        }, []).join('.');
    },


    first: array => {return array ? (Array.isArray(array) ? array[0] : array) : null},
    last: array => array ? array[array.length - 1] : null,

    isTruthy: value => !!value,
    isFalsy: value => !value,
    isEmpty: value => {
        switch (typeof value) {
            case 'string': return value.trim() === '';
            case 'object': return !value;
            case 'undefined': return true;
            default: return false;
        }
    },
    hasSpaces: value => {
        if(typeof value === 'string'){
            return value.includes(' '); 
        }
        return false;
    },
    integerOnly: value => {
        return Number.isInteger(Number(value));
    },
    numberCharacterOnly: value => {
        const regex = /[^0-9]/;
        return regex.test(value);
    },
    letterCharacterOnly: value => {
        const regex = /[^A-Za-z]/;
        return regex.test(value);
    },
    noSpecialCharacters: value => {
        const regex = /[^A-Za-z0-9]/;
        return regex.test(value);
    },
    toJson: object => JSON.stringify(object),
    fromJson: text => JSON.parse(text),
    wait: milliseconds => new Promise(resolve => {
        setTimeout(() => resolve(), milliseconds);
    }),
    throw: message => { throw new Error(message); },
    try: (action, onError, onSuccess) => {
        let p = Promise.resolve().then(action);
        if (onSuccess) p = p.then(onSuccess);
        return p.catch(onError);
    },
    random: withSignature('<:n>', Math.random),
    sample: withSignature(
        '<a:x>',
        (arr) => {
            const ridx = ((Math.random() * arr.length) | 0) % arr.length;
            return arr[ridx];
        }
    ),
    fetch: fetch.bind(window),
    newDate: (new Date()),
    getBusinessDatesCount: (startDate, endDate) => {
        startDate = new Date(jsonataFunctions.toDrDateTime(startDate).format("YYYY/MM/DD h:mm A"));
        endDate = new Date(jsonataFunctions.toDrDateTime(endDate).format("YYYY/MM/DD h:mm A"));
        let count = 0;
        let curDate = parseInt(+startDate / 86400000, 10) * 86400000;
        while (curDate < parseInt(+endDate / 86400000, 10) * 86400000) {
            const dayOfWeek = new Date(curDate).getDay();
            const isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
            if (!isWeekend) {
                count += 1;
            }
            curDate = curDate + 24 * 60 * 60 * 1000
        }
        return count;
    },
    toDrDateTime: datetime => {
        // This is to make all datetime fields consistent, some come with a Timezone.
        if (typeof datetime === 'string') datetime = datetime.replace('Z', '');
        const inUTC = dayjs(datetime).tz("UCT", true);
        return inUTC.tz(DOMINICAN_TZ);
    },
    onlyDate: datetime => {
        // This is to make all datetime fields consistent, some come with a Timezone.
        if (typeof datetime === 'string') datetime = datetime.replace('Z', '');
        const inUTC = dayjs(datetime).tz("UCT", true).format('DD/MM/YYYY');
        return inUTC;
    },
    differenceDays: (startDate, endDate) => {
        startDate = new Date(jsonataFunctions.toDrDateTime(startDate).format("YYYY/MM/DD h:mm A"));
        endDate = new Date(jsonataFunctions.toDrDateTime(endDate).format("YYYY/MM/DD h:mm A"));
        let count = 0;
        let curDate = parseInt(+startDate / 86400000, 10) * 86400000;
        while (curDate < parseInt(+endDate / 86400000, 10) * 86400000) {
            count += 1;
            curDate = curDate + 24 * 60 * 60 * 1000
        }
        return count;
    },
    addDays(date, days) {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    },
    slaColor: (lapsedTime, sla) => {
        sla = sla || [10, 20];
        if (lapsedTime < sla[0]) {
            return SLA_COLORS[0]
        }
        else if (lapsedTime < sla[1]) {
            return SLA_COLORS[1]
        }
        return SLA_COLORS[2];
    },
    compareDates(date1String, date2String) {
        const date1 = new Date(date1String);
        const date2 = new Date(date2String);

        if (!date1 || !date2) {
            return false;
        }

        if (date1 >= date2) {
            return 1;
        } else if (date1 < date2) {
            return 0;
        }
    },
    windowOpen: url => { window.open(url)},
    cloneSet: clone.set,
    hasRole: (roles, searchBy) => {
        if(!roles || !searchBy) return false;
        return roles.some(x => x.role.name === searchBy);
    },
};


export default jsonataFunctions;